.optionList{
    margin: 0px;
    padding-left: 18px;
    max-height: 200px;
    overflow: auto;
}
.optionList li{
    font-size: 14px;
    color: #333;
    text-transform: lowercase;
    margin-bottom: 5px;

}
.optionAction{
    position: absolute;
    top: 10px;
    right: 15px;
}
.optionAction a{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    text-decoration: none !important;
    border-radius: 100%;
    color: #fff;
    transition: 0.3s;
    font-size: 14px;

}
.deleteActn{
    background: #F44336;
    margin-left: 8px;
}
.editActn{
    background: #4CAF50;
}
.optionList::-webkit-scrollbar {
    width: 6px;
    background: transparent;

  }
   
   
  .optionList::-webkit-scrollbar-thumb {
    width: 6px;
    background: #ccc;
    border-radius: 10px;
  }
  .OptionManagement .search-box{
    padding-bottom: 0px;
  }
.flxFrm{
    display: flex;
    align-items: center;

}
.AddEditOption .addBtn{
    /* margin-left: 0px !important; */
}
.scrollBar{
 max-height: 400px;
 overflow-y: auto;
 overflow-x: hidden;
 margin-bottom: 20px;
}
.scrollBar::-webkit-scrollbar {
    width: 6px;
    background: transparent;

  }
   
   
  .scrollBar::-webkit-scrollbar-thumb {
    width: 6px;
    background: #ccc;
    border-radius: 10px;
  }