.howitsWorks .catImagettl{
   flex-direction: column;
   align-items: flex-start;
}
.howitsWorks .catImagettl h2{
    margin-left: 0px;
    margin-top: 20px;
}
.howitsWorks .catIcon{
   width: 100%;
   height: 200px;
}
.howitsWorks .catIcon video{
    width: 100%;
    height: 100%;
}