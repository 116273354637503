.member-header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.member-align{
    display: flex;
    flex-direction: row;
    height: 50px;
    background-color: white;
align-items: center;
justify-content: space-between;
border-radius: 10px;
}