.subscription p{
    margin: 0px;
    width: 300px;
    max-width: 100%;
}
.SubscriptionDetails .catImagettl h2{
    margin-left: 0px;
}
.linetrue{
    text-decoration: line-through;
    display: inline-block;
    color: #E91E63;
}
.SubscriptionDetails  .catDetails{
    margin-top: 20px;
}
.formGroup textarea{
    width: 100%;
    height: 100px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
    padding: 10px 15px;
}
.cutPrice{
    text-decoration: line-through;
    display: inline-block;
    color: #E91E63;
    margin-left: 5px;
    font-size: 100%;
}
.sub_description{
    color: black;
    width: 60px;
}
