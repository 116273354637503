.btngrp .cnclBtn{
    background: rgb(103 58 183 / 44%);
}
.btngrp .cnclBtn:hover{
    background: #673AB7;
}
.accordion{
    border: 0px;
}
.accordion__item{
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    border: 1px solid #673AB7;
   background: #fff;
}
.accordion__button{
    background: #fff;
    color: #673AB7;
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.accordion__button h5{
    margin-top: 10px;
}
.nodata-found-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.accordion__button:hover{
    background: #673AB7; 
    color: #fff; 
}
.accordion__button:focus{
    background: #673AB7; 
    color: #fff; 
}
.accordion__item + .accordion__item{
    border-top: 1px solid #673AB7;
}