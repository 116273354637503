.loding{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    display: inline-flex;
    color: #000;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    align-items: center;
    font-weight: 600;
}
.loding img{
    width: 30px;
    margin-right: 10px;
}