.catIcon{
    width: 60px;
    height: 60px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
}
.catIcon img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
.catImagettl{
    display: flex;
    align-items: center;
    position: relative;
}
.catimg{
    width: 120px;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ccc;
    padding: 15px;

}
.catimg img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
.catImagettl h2{
    font-size: 30px;
    margin: 0px;
    color: #333;
    margin-left: 50px;
}
.catDetails{
    border-top: 1px solid #ccc;
    margin-top: 50px;
    padding-top: 30px;
}
.catDetails h3{
    font-size: 25px;
    color: #333;
    margin-bottom: 10px;
}
.catDetails p{
    font-size: 14px;
    color: #333;
}
.catDetails p label{
    font-size: 14px;
    color: #000;
    margin-right: 10px;
    display: inline-block;
    margin: 0px;
    font-weight: 500;
}
.catDetails .active {
    background: #4caf50;
    color: #fff;
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    margin-left: 5px;
}
.actioncat{
    position: absolute;
    top: 0px;
    right: 0px;
}
.editcat{
    width: 40px;
    height: 40px;
    background: #673ab7;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    border: 1px solid #673ab7;
    transition: 0.3s;
    border-radius: 100%;
    text-decoration: none !important;
}
.editcat:hover{
    background: #fff;
    color: #673ab7;
}
.backcat{
    width: 40px;
    height: 40px;
    background: #fff;
    color: #673ab7;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    border: 1px solid #673ab7;
    transition: 0.3s;
    border-radius: 100%;
    text-decoration: none !important; 
    margin-left: 15px;
}
.editcat:hover{
    background: #673ab7;
    color: #fff;
}
.searchBtn a{
    text-decoration: none !important;
    color: #fff;
}
.searchBtn:hover a{
     color: #673ab7;
}
.flxfrm{
    display: flex;
     align-items: center;
}
td{
    vertical-align: middle !important;
}
.catIcon{
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 15px;
}
.uploadImage{
    display: inline-block;
    color: #fff;
    text-decoration: none !important; 
    cursor: pointer;
    padding: 3px 7px;
    border-radius: 10px;
    background: #673ab7;
    position: relative;
    border: 1px solid #673ab7;
    transition: 0.3s;
}.upload-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploade-text{
    font-size: 12px;
}
.image-dimentiontext{
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.uploadImage input{
    position: absolute;
    inset: 0px;
    font-size: 10px;
    opacity: 0;
    cursor: pointer;
}
.uploadImage:hover{
    background: #fff;
}
.uploadImage:hover {
    color: #673ab7;
}
.formGroup{
    position: relative;
    margin: 0px;
    padding-bottom: 25px;
}
.formGroup label{
    display: block;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-weight: 500;
}
.formGroup input{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    outline: 0px;
    padding: 10px 15px;
    border: 1px solid #ccc;
}
.formGroup select{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    outline: 0px;
    padding: 10px 15px;
    border: 1px solid #ccc;
}
.formGroup .required{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    outline: 0px;
    padding: 10px 15px;
    border: 1px solid #d40f0f;
}
.btngrp{
    display: flex;
    align-items: center;
}
.btngrp button{
    width: auto;
    font-size: 13px;
    min-width: 100px;
    transition: 0.3s;
}
.cancelBtn{
    background: #F44336;
    color: #fff;
    margin-right: 15px;
    border: 1px solid #F44336;
}
.cancelBtn:hover{
    background: #be2318;
}
.category .table-responsive{
    /* height: calc(100vh - 200px); */
    overflow: auto;
}
.themeBtn[disabled]{
    cursor: no-drop;opacity: 0.5;
}