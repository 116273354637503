@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  box-sizing: border-box;
  margin: 0px;
}

a {
  text-decoration: none;
  transition: 0.3;
}
.dashboard .card:hover {
cursor: pointer;
}
.active-menu{
  display: none;
  /* transition: opacity 1s ease 0.2s, max-height 1s ease 0.2s; */
}
.weekly-padinig-box{
  padding: 5px;
}
.weekly-box p{
color: white;
margin-top: 0px;
padding: 0px;

}

.price-profit{
  text-align: center;
  color: white;
}
.weekly-box{
  height: 120px;
  border: 2px solid #f1f1f1;
  background-color: #673AB7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 1px; */
}
.business-profileitem-count{
  height: 25px;
  width: 25px;
  border-radius: 20px;
  border: 2px solid #CECECE;
  display: flex;
  justify-content: center;
  background-color:  #5F259E;
  /* align-items: center; */
}
.back-arrow-button{
  color: white;
  size: 50px;
}
.header-back{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.header-back:hover{
  cursor: pointer;
}
.total_amount_display{
  display: flex;
  justify-content: space-between;
}
button {
  transition: 0.3;
  cursor: pointer;
  outline: 0px !important;
}
.btngrp{
  margin-top: 10px;
}
.acc-d-field{
  /* margin-left: 14px; */
  background-color: #f1f1f1;
  /* padding: 5px; */
  margin-top: 5px;
  border-radius: 5px;
  width: 100%;
  
}
.dl, ol, ul {
  margin-bottom: 0px !important;
}
.items-business-list{
display: flex;
align-content: flex-start;
/* background-color: red; */
/* justify-content: flex-start; */
align-items: flex-start;
}
.items-business-list li{
  align-items: flex-start;
}
.aboutus-section{
  background-color: #000;
}
.aboutus-textarea{
  width: 400px;
  height: 200px;
  border: 2px solid #fff;
}
.about-cms{
  display: flex;
  flex-direction: column;
  /* margin-top: 0px; */
}
.about-sectons{
display: flex;
flex-direction: row;
}
.upload-image-aboutus{
  width: 60px;
  height: 40px;
}
.upload-image-aboutus:hover{
cursor: pointer;
}
.aboutus-image{
  width:380px;
  height: 200px;

}
.cms-image{
  width: 50px;
  height: 50px;
}
/* //



*/
/* src/App.css */
.addres-section{
  display: flex;
  flex-direction: row;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  max-width: 300px; /* Fixed width for pagination container */
  margin: 0 auto;
  padding: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 20px;
  border: none;
  background-color: #c7ccd1;
  color: white;
  cursor: pointer;
  border-radius: 30px;
}

.pagination button.active {
  background-color: #5F259E;
}

.pagination button:hover {
  background-color: #5F259E;
}
.pagination button i{
  color: black;
}
.pagination button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.box-team-acc{
  background-color: #22b14c;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.total-numbers-team{
  color: #22b14c;
  font-size: 15px;
}
.team-incom{
  color: #22b14c;
}
.box-team-acc{

}
.team-details-heading{
  display: flex;
  justify-content: space-between;
}
.box-teammember{
  /* border: 1px solid #727272; */
  background-color: #fff;
}
input {
  outline: 0px;
}

.price-image{
  width: 20px;
  height: 20px;
  color: #22b14c;
  /* background-color: #415094; */
  /* margin-left: -3px; */
}
.team-heading{
  height: 30px;
  background-color: #22b14c;
  display: flex;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.team-heading p{
  color: white;
  text-align: center;
}
.bus-category{
  background-color: #4e1a85;
 border-radius: 5px;
 padding: 5px;
 
}
.bus-category p{
color: white;
/* padding: 3px; */
}
.acc-details-color{
  color: white;
  font-size: 15px;
}
.setting-heading{
display: flex;
justify-content: space-between;
}
.changepass-button{
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-button{
  margin-left: 20px;
}
.change-pass-foem-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.change-password-box{
  width: 120px;
  background-color: red;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.changepassword-text{
  margin-top: 12px;
}
.change-password-box p{
  font-size: 13px;
}
.business-images{
  margin-left: 2px;
  /* padding: 5px; */
  /* background-color: #22b14c; */
}
.image-container{
  margin-left: 5px;
}
.menu-main-item {
  display: flex;
  justify-content: space-between;
}
.menu-main-item:hover{
 cursor: pointer;
}
/* .sl-number-businessprofile{
  width: 80;
  height: 50;
  border-radius: 10px;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.acc-details-image{
  height: 200px;
  width: 100px;
  margin-top: 5px;
  margin-left: 5px;
}
.table-column{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.back-button{
  width: 40px;
  height: 40px;
  background-color: #22b14c;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formmangement{
  display: flex;
  justify-content: space-between;
}
.pending_color {
  /* background-color: #3BBA00; */
  color: orange;
}

.flex_withdraw {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cancelcolor {
  color: red;
}
.empty-field{
  border: 1px solid red;
}

.approvecolor {
  color: green;
}

/* font-family: "Plus Jakarta Sans", sans-serif; */
/* font-family: "Barlow", sans-serif; */
/* font-family: "Roboto", sans-serif; */
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
}

.logwapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
}

.logwapper-body {
  width: 1140px;
  border-radius: 40px;
  padding: 40px;
  background: #fff;
  box-shadow: 0px 0px 5px #ccc;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.logwapper-left {
  width: 50%;
  background-color: #5F259E;
  border-radius: 20px;
  overflow: hidden;
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('./assets/city.png');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.logwapper-right {
  width: 50%;
}

.logwapper-right form {
  width: 430px;
  margin: 0 auto;
}

.logwapper-right form h1 {
  font-size: 40px;
  text-align: center;
  color: #000;
  font-weight: 300;
  margin: 0px;
  margin-bottom: 25px;

}

.form-group {
  padding-bottom: 25px;
  position: relative;
  margin: 0px;
}

.form-group input {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 14px;
  padding-left: 40px;
}

.checkboxs label {
  position: relative;
  font-size: 14px;

}

.checkboxs label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #5F259E;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  width: 20px;
  height: 20px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkboxs input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 7px;
  width: 6px;
  height: 14px;
  border: solid #5F259E;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkboxs input {
  width: 0px;
  height: 0px;
  visibility: hidden;
  opacity: 0;
}

.flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;

}

.flx a {
  display: inline-block;
  color: #5F259E;
  font-size: 10px;
}

.flx a:hover {
  color: #000;
}

.themeBtn {
  height: 50px;
  background: #5F259E;
  border-radius: 6px;
  color: #fff;
  text-transform: uppercase;
  border: 0px;
  width: 100%;
}

.themeBtn:hover {
  background: #4e1a85;
}

.layout {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.nav {
  width: 300px;
  position: relative;
  transition: 0.3s;
  background: #5f259e;
  display: block;
  height: 100%;
}

.nav ul {
  padding: 0px;
  margin: 0px;
  list-style: none;

  overflow: auto;
}

.main-nav {
  height: calc(100vh - 75px);
  /* transition: opacity 0.5s ease 0.2s, max-height 0.5s ease 0.2s; ; */
}

.nav ul li {
  width: 100%;
}

.nav ul::-webkit-scrollbar {
  width: 6px;
  background: transparent;

}

.nav ul::-webkit-scrollbar-thumb {
  width: 6px;
  background: #ccc;
  border-radius: 10px;
}

.nav ul li a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 15px 20px;
  position: relative;
}

.nav ul li a i {
  font-size: 20px;
  margin-right: 10px;

}

.sub-menu {
  margin: 0px;
  display: none;
}

.sub-menu-item a {
  color: white !important;

}

.submenu_item_active a {
  background-color: #ccc;
}

.sub-menu li a {
  color: #fff;
  font-size: 14px !important;
  padding: 8px 20px !important;
  padding-left: 50px !important;
  background: #4e1a85 !important;

}

.border-subscription {
  border-color: #333;
  border-style: dashed;
  border-width: 1px;
}
.member-expiridata{
  color: red;
}
.nodata-found-div{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.table-image{
  height: 100px;
  width: 150px;
}
.body-table-nodata td{
  /* display: flex; */
  width: 100vi;
  /* justify-content: center; */
  /* align-items: center; */
  background-color: #22b14c;
  vertical-align: middle;
}
.sub-menu li a:hover {
  background: #4e1a85 !important;
  color: #fff !important;
}

.wapper {
  transition: 0.3s;
  width: calc(100% - 300px);
  position: relative;
}

.form-group i {
  color: #5f259e;
  position: absolute;
  top: 15px;
  font-size: 20px;
  left: 15px;
}

header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  box-shadow: 0px 4px 5px rgb(204 204 204 / 52%);
}
.toogle-menuopen{
  cursor: pointer;
}
.header_active{
  display: flex;
  width: 100vi;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  box-shadow: 0px 4px 5px rgb(204 204 204 / 52%);
}
.header-left {
  display: flex;
  align-items: center;
}

.header-left a {
  display: inline-block;

}

.header-left h2 {
  margin: 0px;
  font-size: 20px;
  color: #000;
  font-weight: 500;
  padding-left: 15px;
}

.user {
  display: flex;
  align-items: center;
}

.user span {
  width: 40px;
  height: 40px;
  border: 2px solid #4e1a85;
  display: block;
  border-radius: 100%;
  margin-right: 15px;
  overflow: hidden;
}

.user span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user label {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  margin: 0px;
}

.user a {
  display: inline-block;
  color: #000;
  font-size: 16px;
}

.wapperbody {
  width: 100%;
  height: calc(100vh - 70px);
  background: #F2E9FD;
  padding: 20px;
  overflow: auto;
  /* transition: opacity 0.3s ease-in-out; */

}

.wapperbody_menuactive {
  width: 100vi;
  height: calc(100vh - 70px);
  background: #F2E9FD;
  padding: 20px;
  overflow: auto;
  /* transition: opacity 0.3s ease-in-out; */


}
.wapperbody::-webkit-scrollbar {
  width: 6px;
  background: transparent;

}

.wapperbody::-webkit-scrollbar-thumb {
  width: 6px;
  background: #ccc;
  border-radius: 10px;
}

.nav ul .fa-angle-down {
  position: absolute;
  right: 20px;
  font-size: 16px;
}

/* .nav ul li:hover {
  background: #fff;
} */
/* 
.nav ul li:hover a {
  color: #673AB7;
} */

.nav ul li.active a {
  background: #fff;
  color: #673AB7;
  /* margin-top: 10px; */
}
.nav ul li.active a{
cursor: pointer;
}
.card {
  padding: 20px;
  position: relative;
  border-radius: 15px;
  margin-bottom: 15px;
}

.card h2 {
  font-size: 16px;
  color: #000;
  margin: 0px;
  margin-bottom: 15px;
  font-weight: 500;
}
.account-details{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f1f1f1   ;
  margin-top: 5px;
  padding: 6px;
}
.addres-account-details{
  align-items: center;
  background-color: #f1f1f1   ;
  margin-top: 5px;
  padding: 6px;
}
.addres-account-details p{
  color: #415094;
}
.account-details P{
  color: #415094  !important;
}
.cardBtm {
  display: flex;
  align-items: center;
}

.cardBtm span {
  font-size: 20px;
  color: #000;
  display: block;
  margin-left: 15px;
  font-weight: 600;
}

.cardBtm img {
  width: 30px;
}

.card small {
  position: absolute;
  right: 15px;
  top: 20px
}

.inactive {
  background: #d70707;
  color: #fff;
  font-size: 13px;
  padding: 2px 5px;
  border-radius: 5px;
}

.sucess {
  background: #4CAF50;
  color: #fff;
  font-size: 13px;
  padding: 2px 5px;
  border-radius: 5px;
}

.flxcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.blocknumber {
  margin-left: 0px !important;
  color: #d70707 !important;
}

.cardBtm {
  width: 100%;
}

.cardBtm ul {
  margin: 0px -5px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
}

.cardBtm ul li {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  background: #673AB7;
  margin-right: 10px;
  border-radius: 10px;
}

.cardBtm ul li span {
  padding: 6px;
  margin: 0px !important;
  color: #fff;
  font-size: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 33px;
  text-align: center;
  border-radius: 5px;
  display: block;
  background-image: url('./assets/book.png');
  background-repeat: no-repeat;
}

.searchtct {
  position: relative;

}

.searchtct i {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #673AB7;
}

.searchtct input {
  height: 45px;
  width: 350px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 10px;
  padding-left: 35px;

}
.searchtct select {
  height: 45px;
  width: 350px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 14px;
  outline: none;
  border-radius: 10px;
  padding-left: 35px;
  margin-left: 5px;

}

.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.table {
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  margin: 0px;
  border-spacing: 0px;
}

.table thead th {
  border-bottom: 0px;
}

.table thead th {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: 0px;
  background: #5F259E;
  padding: 15px 15px;
  white-space: nowrap;
}

.table tbody td {
  font-size: 14px;
  color: #333;
  padding: 15px 15px;
  border-bottom: 1px solid #ccc;
}

.action a {
  display: inline-block;
  padding: 0px 5px;
  color: #000;
  font-size: 16px;
}

.action a:hover {
  color: #000;
}

.action .fa-lock {
  color: #3BBA00;
}

.action .fa-pencil {
  color: #5F259E;
}

.action .fa-trash {
  color: #ED0000;
}

td .active {
  background-color: #3BBA00;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

td .inactive {
  background-color: #ED0000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

.active_profile {
  background-color: #3BBA00;
  color: #fff;
  padding: 2px 3px;
  border-radius: 5px;
  font-size: 10px;
  width: 50px;
  text-align:center;
  display: inline-block;
}
.acc-profile-image-section{
  display: flex;
  flex-direction: row;
  height: 200px;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 10px;
}
.acc-profile-image-section p{
color: #415094;
}
.image-acc-details{
  height: 100px;
  width: 70px;
  /* margin-left: 5px; */
}
.business-text{
  font-size: 14px;
  color: black;
  font-weight: 500;
}
.business-images{
  padding: 5px;

}
.business_profile{
  border: 2px solid #f1f1f1;
  margin-left: 2px;
  padding: 5px;
  border-radius: 10px;
  /* padding: 5px; */
}
.position-text{
  color: #4CAF50;
}
.inactive_profile {
  background-color: #3BBA00;
  color: #fff;
  padding: 2px 3px;
  border-radius: 5px;
  font-size: 10px;
  width: 50px;
  text-align:center;
  display: inline-block;
}
.image-container{
  border: 2px solid #f1f1f1  ;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E0D9D9;
}
.tablepgntn ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
}
.password-not-match{
  color: red;
  font-size: 10px;
}
.tablepgntn ul li {
  margin-right: 15px;
}

.tablepgntn ul li a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  border-radius: 100%;
  text-decoration: none;
}
.income-view-box {
  background-color: #3f48cc ;
  color: white;
}
.member-butonborder{
  border:1px solid white;
}
.income-view-box2{
  background-color: #22b14c ;
  color: white;
}
.tablepgntn ul li a:focus {
  background: #4e1a85;
  color: #fff;
}

.tablepgntn ul li a:hover {
  background: #4e1a85;
  color: #fff;
}

.tablepgntn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
}

.tablepgntn p {
  font-size: 16px;
  margin: 0px;
  color: #000;
}

.tablepgntn p select {
  width: 50px;
  height: 35px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  outline: 0px;
  text-align: center;
  margin: 0px 8px;
}

.addBtn {
  height: 45px;
  background: #673AB7;
  color: #fff;
  font-size: 14px;
  border: 0px;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
  outline: none !important;
}

/* .addBtn:hover {
  background: #4e1a85;
} */

.card_form {
  padding: 20px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 16px;
}

.card_form .form-group input {
  padding-left: 15px;
  height: 40px;
}

.card_form .form-group label {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}

.card_form .form-group select {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 14px;
}

.btn_group button {
  font-size: 14px;
  color: #fff;
  border: 0px;
  cursor: pointer;
  padding: 7px 15px;
  border-radius: 6px;
  outline: 0px;
}

.add-btn {
  background: #4e1a85;
  color: #000;
}

.add-btn:hover {
  background: #673AB7;
}

.cancl-btn {
  background: rgb(103 58 183 / 28%);
  color: #673AB7;
  margin-left: 8px;
}

.cancl-btn:hover {
  background: #5f259e;
  color: #fff;
}

.modalpop {
  background: rgb(0 0 0 / 74%);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 9;
  inset: 0px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.modalsbody {
  border-radius: 20px;
  padding: 25px;
  width: 500px;
  background: #fff;
  transition: 0.2s;
  transform: translate(0px, 50px);
  opacity: 0;
  visibility: hidden;

}

.modalsbody {
  text-align: center;
}

.modalsbody h3 {
  font-size: 35px;
  margin: 0px;
  text-align: center;
  margin-bottom: 20px;
}

.popBtn button {
  border: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  outline: 0px;
  margin: 0px 5px;
}

.secussBtn {
  background: #4CAF50;
  color: #fff;
}
.button-with{
  background-color: #333;
  color: black;
}

.cancelBtn {
  background: #fd1f0e;
  color: #fff;
}

.open.modalpop {
  opacity: 1;
  visibility: visible;
}

.open .modalsbody {
  transform: translate(0px, 0px);
  opacity: 1;
  visibility: visible;
}

.active .sub-menu {
  display: block;
  /* background-color: #3BBA00; */
}

/* .drop-downfield{} */
.userdtls {
  position: relative;
}

.userdtls small {
  font-size: 100%;
  position: absolute;
  right: 0px;
  background: #fff;
  display: inline-block;
  padding: 6px 30px;
  box-shadow: 0px 2px 5px #ccc;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.userdtls small:hover {
  background: #5f259e;
  color: #fff;
}

.userdtls small.open {
  opacity: 1;
  top: 36px;
  visibility: visible;
}

.userdtls b {
  margin-left: 5px;
  cursor: pointer;
}

.nav a.logo {
  display: block;
  text-align: center;
}

.nav a.logo img {
  width: 150px;
}

.action {
  display: flex;
}

.item-list {
  display: flex;
  flex-direction: row;
}

.cross-icon {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.icon {
  color: red;
  font-size: 26px;
}

.name-width {
  width: 200px;
}
/* ///
switch button
*/
/* Switch.css */

/* Switch.css */

.switch {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 24px;
  background-color: #ccc;
  border-radius: 34px;
  position: relative;
  transition: background-color 0.3s;
  user-select: none; /* Prevent text selection */
}

.switch.on {
  background-color: #4caf50; /* Green color when ON */
}

.switch.off {
  background-color: #ccc; /* Grey color when OFF */
}

.switch-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
  transform: translateX(2px);
}

.switch.on .switch-handle {
  transform: translateX(25px); /* Move the handle to the right when ON */
}

/* ///star

*/


.star-rating {
  display: inline-flex;
  cursor: pointer;
  font-size: 15px; /* Adjust size as needed */
}

.star {
  color: #ccc; /* Gray color for empty stars */
  transition: color 0.2s;
}

.star.filled {
  color: #ffcc00; /* Yellow color for filled stars */
}
.notification_image{
  width: 30px;
  height: 30px;
  border-radius: 100px;
}
.profilesub-main{
  margin-top: 20px;
}