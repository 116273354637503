.searchBtn{
    border: 1px solid #673AB7;
    border-radius: 8px;
    background: #673AB7;
    color: #fff;
    font-size: 14px;
    padding: 10px 15px;
    cursor: pointer;
    margin-left: 10px;
    transition: 0.3s;
}
.searchBtn:hover{
    background: #fff;
    color: #673AB7;
}
.usersec .table-responsive{
    /* height: calc(100vh - 290px); */
    overflow: auto;
}
.addBtn[disabled]{
    cursor: no-drop;opacity: 0.5;
}
.curso{
    cursor: pointer;
}